import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=12576e86"
import script from "./CreateUser.vue?vue&type=script&lang=js"
export * from "./CreateUser.vue?vue&type=script&lang=js"
import style0 from "./CreateUser.vue?vue&type=style&index=0&id=12576e86&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports